const Sentry = require('@sentry/node');

function getCookie(req, key) {
  return req.cookies[key];
}

function getCasIDFromURL(casURL) {
  if (!casURL) return '';
  const urlSplitCas = casURL.split('/');
  if (!urlSplitCas.length) return '';
  const casID = urlSplitCas[urlSplitCas.length - 1].split('.')[0];
  if (!casID) return '';
  return casID;
}

function getServerSideCasID(req) {
  const casURL = getCookie(req, 'cas');
  return getCasIDFromURL(casURL);
}

// These errors are logged to the camc-node project
function captureServerSideError(error, req) {
  Sentry.withScope((scope) => {
    if (req) {
      const casID = getServerSideCasID(req);
      if (casID) scope.setTag('cas', casID);
    }
    Sentry.captureException(error);
  });
}

// These events are logged to the camc-node project
function captureServerSideEvent(event, req) {
  Sentry.withScope((scope) => {
    if (req) {
      const casID = getServerSideCasID(req);
      if (casID) scope.setTag('cas', casID);
    }
    Sentry.captureEvent(event);
  });
}

module.exports = {
  captureServerSideError,
  captureServerSideEvent,
  getCasIDFromURL,
};
