/* eslint-disable */
import gql from 'graphql-tag';
import Router from 'next/router';
import { merge, pickBy, identity } from 'lodash';

import GET_CAMPSITE_COMPARISON from '../components/Comparison/graphql/getCampsiteComparison';

const resolver = {
  persistentDefaults: {
    persistBasketValidation: false,
  },
  defaults: {
    availability: {
      __typename: 'Availability',
      errorCode: 0,
      pitchTypes: [],
    },
    basketPopUp: {
      __typename: 'BasketPopUp',
      open: false,
      name: '',
    },
    campsiteSortType: 0,
    depositComponentSummaryPopUp: {
      __typename: 'DepositComponentSummaryPopUp',
      open: false,
      components: [],
    },
    siteNightVoucherPopUp: {
      __typename: 'SiteNightVoucherPopUp',
      open: false,
      minimum: 0,
    },
    noticePopUp: {
      __typename: 'NoticePopUp',
      open: false,
      type: '',
    },
    formPopUp: {
      __typename: 'FormPopUp',
      open: false,
      type: '',
    },
    becomeAMemberPopUp: false,
    bookingOverlapDialog: false,
    voucherDialog: false,
    loginPopUp: {
      __typename: 'LoginPopUp',
      open: false,
      title: '',
      prompt: '',
      redirectTo: '',
      showMembership: true,
    },
    comparisonCampsites: {
      __typename: 'ComparisonCampsites',
      campsites: [],
      crossings: [],
    },
    compareModuleState: {
      __typename: 'CompareModuleState',
      active: false,
      siteNightVoucher: {
        __typename: 'SiteNightVoucherInfo',
      }
    },
    confirmationModalState: {
      __typename: 'ConfirmationModalState',
      active: false,
    },
    cancelSiteBookingPopUp: {
      __typename: 'CancelSiteBookingPopUp',
      open: false,
      refund: 0,
      title: '',
    },
    upSellPopUp: {
      __typename: 'UpSellPopUp',
      open: false,
      saving: 0,
    },
    membershipExpiredPopUp: {
      __typename: 'MembershipExpiredPopUp',
      open: false,
      isOverseas: false,
      showProceed: false,
    },
    removePackagePopUp: {
      __typename: 'RemovePackagePopUp',
      open: false,
      componentId: '',
    },
    removeVouchersPopUp: {
      __typename: 'RemoveVouchersPopUp',
      open: false,
      componentId: '',
    },
    richTextPopUp: {
      __typename: 'RichTextPopUp',
      open: false,
      text: '',
    },
    user: {
      __typename: 'User',
      memberStatus: null,
    },
    userOutfits: {
      __typename: 'UserOutfits',
      outfits: [],
    },
    pinInfo: {
      __typename: 'PinInfo',
      events: [],
    },
  },
  resolvers: {
    Mutation: {
      updateComparisonCampsites: (_, { comparisonCampsites }, { cache }) => {
        const previous = cache.readQuery({ query: GET_CAMPSITE_COMPARISON });

        const data = {
          ...previous,
          comparisonCampsites: {
            ...previous.comparisonCampsites,
            ...comparisonCampsites
          }
        };

        cache.writeData({ data });

        return null;
      },
      updateCompareModuleState: (_, { active }, { cache }) => {
        const data = {
          compareModuleState: {
            __typename: 'CompareModuleState',
            active,
          },
        };
        cache.writeData({ data });
        return null;
      },
      updateConfirmationModalState: (_, { active }, { cache }) => {
        const data = {
          confirmationModalState: {
            __typename: 'ConfirmationModalState',
            active,
          },
        };
        cache.writeData({ data });
        return null;
      },
    },
  },
};

export default resolver;

export const { persistentDefaults } = resolver;
export const { defaults } = resolver;
export const { resolvers } = resolver;
